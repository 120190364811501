import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import api from "../../../services/api";
import { Toast } from "primereact/toast";

export default function BrandModelModal({ visible, onHide, type, onSuccess }) {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const toast = useRef(null);

  const isBrand = type === "brand";
  const endpoint = isBrand ? "/brands" : "/models";

  const handleCreate = async () => {
    try {
      const data = {
        name,
        is_active: isActive
      };

      await api.post(endpoint, data);

      toast.current.show({
        severity: "success",
        summary: "",
        detail: `${isBrand ? "Marca" : "Modelo"} criado com sucesso!`,
        life: 5000
      });

      if (onSuccess) {
        onSuccess();
      }

      setName("");
      setIsActive(true);
      onHide();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Erro ao criar. Verifique os dados e tente novamente.",
        life: 5000
      });
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleCreate}
      />
    </React.Fragment>
  );

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header={isBrand ? "Nova Marca" : "Novo Modelo"}
        modal
        footer={dialogFooter}
        onHide={onHide}
      >
        <div className="p-fluid">
          <div className={"col-md-12 pb-3"}>
            <label htmlFor="name">Nome</label>
            <InputText
              id="name"
              value={name}
              className="py-3"
              onChange={(e) => setName(e.target.value)}
              required
              autoFocus
            />
          </div>

          {/* <div className="field-checkbox">
            <Checkbox
              inputId="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.checked)}
            />
            <label htmlFor="isActive">Ativo?</label>
          </div> */}
        </div>
      </Dialog>
    </>
  );
}
