import QuantityCard from "../../components/QuantityCard";
import "./dashboard.css";
import DateRange from "../../components/ToolbarComponents/DateRange";
import { useRef, useState } from "react";
import { Chart } from "primereact/chart";
import { useQuery } from "react-query";
import api from "../../services/api";
import { secondsToHoursMinutes } from "../../utils/masks";
import DateRangeDashboard from "../../components/ToolbarComponents/DateRangeDashboard";

export default function Dashboard() {
  const currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );

  const [beginDate, setBeginDate] = useState(currentDate);
  const [endDate, setEndDate] = useState("");
  const [tecnicos, setTecnicos] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("all");
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [techs, setTechs] = useState([]);
  const chartRef = useRef();
  const { data: techsData } = useQuery({
    queryFn: async () => {
      return await api.get("/users/getTechs");
    },
    queryKey: ["techs"],
    onSuccess: (data) => {
      setTecnicos(data.data.filter((user) => user.user_type === "Tech"));
    }
  });

  const { data: reportsCountData } = useQuery({
    queryFn: async () => {
      return await api.post(`/reports/dashboard/reports-count`, {
        beginDate: beginDate,
        endDate: endDate,
        createdBy: techs.join(",")
      });
    },
    queryKey: ["reports-count", techs, beginDate, endDate]
  });

  const { data: serviceOrderStatusCountData } = useQuery({
    queryFn: async () => {
      return await api.get(
        `/service_order/getStatusCount?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${
          endDate ? endDate?.toISOString().slice(0, 10) : ""
        }`
      );
    },
    queryKey: ["servicer-order-status-count", beginDate, endDate]
  });

  const { data: serviceOrderWorkedTimeData } = useQuery({
    queryFn: async () => {
      return await api.get(
        `/service_order/dash/getWorkedTime?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${
          endDate ? endDate?.toISOString().slice(0, 10) : ""
        }`
      );
    },
    queryKey: ["servicer-order-worked-time", beginDate, endDate]
  });

  const reportsCount = reportsCountData?.data?.reports_count;
  const { data: dailyHoursData } = useQuery({
    queryFn: async () => {
      return await api.post(`/reports/dashboard/daily-hours`, {
        beginDate: beginDate,
        endDate: endDate,
        createdBy: techs.join(",")
      });
    },
    queryKey: ["daily-hours", techs, beginDate, endDate]
  });

  const dailyHours = dailyHoursData?.data?.daily_hours;

  const getDailyHoursDataByType = (type) => {
    if (!dailyHours) return [];
    switch (type) {
      case "all":
        return dailyHours?.all || [];
      case "correctives":
        return dailyHours?.correctives || [];
      case "preventives":
        return [
          ...(dailyHours?.preventives || []),
          ...(dailyHours?.preventivesCombustions || [])
        ];
      default:
        return dailyHours?.all || [];
    }
  };

  const dailyHoursSelected = getDailyHoursDataByType(selectedReportType);

  const dailyHoursChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name),
    datasets: [
      {
        label: "",
        backgroundColor: "#f3c842",
        data: dailyHoursSelected.map((hour) => hour.total_seconds)
      }
    ]
  };

  const dailyHoursChartOptions = {
    legend: {
      display: false
    },
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          }
        },
        type: "linear",
        title: {
          display: true,
          text: "Tempo (HH:mm)"
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            // Convert total seconds to hours and minutes for tooltip
            const value = context.raw;
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `Tempo: ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          }
        }
      },
      legend: {
        display: false
      }
    }
  };
  const dailyHoursInAttendanceChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name),
    datasets: [
      {
        label: "",
        backgroundColor: "#f3c842",
        data: dailyHoursSelected.map((hour) => hour.attendance_seconds)
      }
    ]
  };

  const reportOptionsChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name),
    datasets: [
      {
        label: "",
        backgroundColor: "#f3c842",
        data: dailyHoursSelected.map((hour) => hour.count)
      }
    ]
  };

  const reportQuantityChartOptions = {
    legend: {
      display: false
    },
    indexAxis: "y",
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const handleSelectedTech = (id) => {
    if (techs.includes(id)) {
      setTechs(techs.filter((tech) => tech !== id));
      return;
    }
    setTechs([...techs, id]);
  };

  const handleSumAllServiceOrders = () => {
    return (
      serviceOrderStatusCountData?.data?.closed +
      serviceOrderStatusCountData?.data?.running +
      serviceOrderStatusCountData?.data?.opened
    );
  };

  return (
    <div>
      <div className="dashboard-cards">
        <QuantityCard
          label={"Qtd Relatórios"}
          isSelected={false}
          color={"#blue"}
          value={reportsCount?.all ?? 0}
        />
        <QuantityCard
          label={"Qtd Corretivas"}
          isSelected={selectedReportType === "correctives"}
          color={"purple"}
          clickable={true}
          value={reportsCount?.correctives ?? 0}
          onClick={() => {
            if (selectedReportType === "correctives") {
              setSelectedReportType("all");
            } else {
              setSelectedReportType("correctives");
            }
          }}
        />
        <QuantityCard
          label={"Qtd. Preventivas"}
          isSelected={selectedReportType === "preventives"}
          color={"purple"}
          value={
            (reportsCount?.preventives ?? 0) +
            (reportsCount?.preventives_combustions ?? 0)
          }
          onClick={() => {
            if (selectedReportType === "preventives") {
              setSelectedReportType("all");
            } else {
              setSelectedReportType("preventives");
            }
          }}
        />
        <QuantityCard
          label={"Hr. Trabalhada"}
          isSelected={false}
          color={"orange"}
          value={secondsToHoursMinutes(
            dailyHours?.all?.reduce((sum, obj) => sum + obj.total_seconds, 0)
          )}
        />

        <QuantityCard
          label={"Os Aberta"}
          isSelected={false}
          color={"red"}
          value={serviceOrderStatusCountData?.data?.opened ?? 0}
        />
        <QuantityCard
          label={"Os Fechada"}
          isSelected={false}
          color={"green"}
          value={serviceOrderStatusCountData?.data?.closed ?? 0}
        />
        <QuantityCard
          label={"Os Total"}
          isSelected={false}
          color={"cyan"}
          value={handleSumAllServiceOrders() ?? 0}
        />
        <QuantityCard
          label={"Os Hr. Trab"}
          isSelected={false}
          color={"orange"}
          value={serviceOrderWorkedTimeData?.data?.workedTime ?? 0}
        />
      </div>
      <div>
        <div className="techs-header">
          <h2>Técnicos</h2>
          <div className="dashboard-date-range">
            {/* <DateRange
              inputBeginDate={inputBeginDate}
              setInputBeginDate={setInputBeginDate}
              beginDate={beginDate}
              setBeginDate={setBeginDate}
              setInputEndDate={setInputEndDate}
              setEndDate={setEndDate}
              inputEndDate={inputEndDate}
              endDate={endDate}
            /> */}
            <DateRangeDashboard
              inputBeginDate={inputBeginDate}
              setInputBeginDate={setInputBeginDate}
              beginDate={beginDate}
              setBeginDate={setBeginDate}
              setEndDate={setEndDate}
              inputEndDate={inputEndDate}
              setInputEndDate={setInputEndDate}
            />
          </div>
        </div>
        <div className="dashboard-techs-box ">
          {tecnicos &&
            tecnicos?.map((tech, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleSelectedTech(tech.id)}
                  className={`dashboard-techs ${
                    techs.includes(tech.id) && "selected"
                  }`}
                >
                  <strong>{tech?.name?.split(" ")[0]}</strong>
                </div>
              );
            })}
        </div>
      </div>

      <div className="dashboard-charts">
        <div className="dashboard-chart-box">
          <span className="chart-box-title">Horas apontadas</span>
          <Chart
            chartRef={chartRef}
            type="bar"
            data={dailyHoursChartData}
            options={dailyHoursChartOptions}
          />
        </div>

        <div className="dashboard-chart-box">
          <span className="chart-box-title">Horas em Atendimento</span>
          <Chart
            type="bar"
            data={dailyHoursInAttendanceChartData}
            options={dailyHoursChartOptions}
          />
        </div>

        <div className="dashboard-chart-box">
          <span className="chart-box-title">Qtd. Relatório por Técnico</span>
          <Chart
            type="bar"
            data={reportOptionsChartData}
            options={reportQuantityChartOptions}
          />
        </div>
      </div>
    </div>
  );
}
