import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import ContextTable from "../../../components/ContextTable";
import QuantityCard from "../../../components/QuantityCard";
import ServiceOrderForm from "../../../components/ServiceOrdersForm/ServiceOrderForm";
import { useAuth } from "../../../contexts/AuthContext";
import { useServiceOrder } from "../../../contexts/ServiceOrderContext";
import { tableData } from "../../../contexts/ServiceOrderContext/ConstData";
import { withServiceOrderContext } from "../../../contexts/ServiceOrderContext/withServiceOrder";
import api from "../../../services/api";

function objectToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      );
    }
  }
  return keyValuePairs.join("&");
}

const ServiceOrderTable = () => {
  const context = useServiceOrder();
  const { token } = useAuth();
  const toast = useRef(null);
  const [opened, setOpened] = useState(0);
  const [running, setRunning] = useState(0);
  const [closed, setClosed] = useState(0);
  const [filterObject, setFilterObject] = useState({});

  useEffect(() => {
    api
      .get(`service_order/getStatusCount?${objectToQueryString(filterObject)}`)
      .then((response) => {
        setOpened(response.data.opened);
        setRunning(response.data.running);
        setClosed(response.data.closed);
      });
  }, [filterObject]);

  const openFormModal = async (rowData = null) => {
    document.body.style.position = "fixed";
    context.setFormModalOpened(true);
    if (rowData) {
      await context.setSelectedItem(rowData);
    }
  };
  const hideFormModal = () => {
    document.body.style.position = "relative";
    context.setFormModalOpened(false);
  };

  const actionTemplate = (rowData, access_type) => {
    return (
      <React.Fragment>
        <Button
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => openFormModal(rowData)}
        />

        <Button
          icon="pi pi-th-large"
          tooltip="Qr Code"
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: "#1c3424", outline: 0, border: "none" }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            window
              .open(
                process.env.REACT_APP_LARAVEL_APP +
                  `api/service_order/qr_code?id=${rowData.id}&access_token=${token}`,
                "_blank"
              )
              .focus();
          }}
        />

        <Button
          tooltip="Download relátorio"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-file-pdf"
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() =>
            window.open(`/pdf/service_order;${rowData.id}`, "_blank").focus()
          }
        />
      </React.Fragment>
    );
  };

  tableData.find((data) => data.header === "Ações").body = actionTemplate;

  const handleChangeStatus = (status) => {
    if (status === selectedStatus) {
      setSelectedStatus("");
      return;
    }
    setSelectedStatus(status);
  };

  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <div>
      <Toast style={{ zIndex: 1101 }} ref={toast} />
      <ServiceOrderForm
        visible={context.formModalOpened}
        onHide={hideFormModal}
        toast={toast}
        selectedStatus={selectedStatus}
      />
      <>
        <div
          className={
            "d-flex flex-row justify-content-around align-content-center pb-2 gap-2"
          }
        >
          <QuantityCard
            onClick={() => handleChangeStatus("Aberto")}
            label={"Abertos"}
            isSelected={"Aberto" === selectedStatus}
            color={"#9E3131"}
            value={opened}
          />
          <QuantityCard
            onClick={() => handleChangeStatus("Fechado")}
            label={"Fechados"}
            isSelected={"Fechado" === selectedStatus}
            color={"#003B17"}
            value={closed}
          />
          <QuantityCard
            onClick={() => handleChangeStatus("Em andamento")}
            label={"Em andamento"}
            isSelected={"Em andamento" === selectedStatus}
            color={"#FAC02D"}
            value={running}
          />
        </div>
      </>
      <ContextTable
        type={"ServiceOrder"}
        setFilterObject={setFilterObject}
        selectedStatus={selectedStatus}
        context={context}
        openForm={openFormModal}
        defaultOrderBy="created_at"
      />
    </div>
  );
};

export default withServiceOrderContext(ServiceOrderTable);
